import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: "2rem",
    ["&.MuiDialogTitle-root"]: {
      backgroundColor: theme.palette.background.accent,
    },
  },
}));
export default useStyles;
