import { createMuiTheme } from "@material-ui/core/styles";
// colors 7a878c,f33b54,393e41,119da4,ffffff
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F33B54",
    },
    secondary: {
      main: "#119DA4",
    },
    background: {
      default: "#282C2F",
      paper: "#454b4f",
      accent: "#7A878C",
    },
    text: { primary: "#ffffff", secondary: "#ffffff5e", disabled: "#ffffff5e" },
    action: {
      active: "#7A878C",
    },
  },
});
export default theme;
