import { useCallback, useEffect, useRef } from "react";

import axios from "axios";
import { useSessionStorage } from "./use_storage";

export const useAxiosCancel = (callback, deps = [], config = {}) => {
  const CancelToken = axios.CancelToken;
  const source = useRef(CancelToken.source());
  const [token] = useSessionStorage(null, "token");
  const newCallback = useCallback(() => {
    let defaultHeaders = { "Access-Control-Allow-Origin": true };
    if (token)
      defaultHeaders = { ...defaultHeaders, Authorization: `Bearer ${token}` };
    const axiosInstance = axios.create({
      headers: { ...defaultHeaders },
      cancelToken: source.current.token,
      ...config,
    });
    return callback(axiosInstance, source.current);
  }, [deps, config]);
  useEffect(() => {
    return () => {
      if (source.current) source.current.cancel();
    };
  }, []);
  return newCallback;
};
