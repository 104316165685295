import { Add, ExitToApp } from "@material-ui/icons";
import { CircularProgress, Container, Fab, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ConfirmActions } from "../components/confirm_actions/confirm_actions";
import { FormActions } from "../components/form_actions/form_actions";
import Modal from "../../components/modal/modal";
import { PasswordCard } from "../components/password_card/password_card";
import { PasswordForm } from "./../components/password_form/password_form";
import { SearchBox } from "../components/search_box/search-box";
import { isCancel } from "axios";
import { useAxiosAuth } from "../../../app/hooks/use_axios_auth";
import { useAxiosCancel } from "../../../app/hooks/use_axios_cancel";
import { useHistory } from "react-router-dom";

const empty = {
  title: "",
  user: "",
  password: "",
  site: "",
  tags: "",
};
const api_url = process.env.REACT_APP_API_URL;

export const Dashboard = () => {
  const [selected, setSelected] = useState(empty);
  const [query, setQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    getAccounts(query);
  }, [query]);

  const getAccountsRequest = useAxiosAuth(
    (axios) => {
      return axios.get(
        `${api_url}/accounts${query !== "" ? `?query=${query}` : ""}`
      );
    },
    [query],
    "/"
  );
  const getAccounts = (query) => {
    setLoading(true);
    return getAccountsRequest(query)
      .then((response) => {
        setAccounts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (!isCancel(error)) setLoading(false);
      });
  };

  const saveAccount = useAxiosCancel(
    (axios) => {
      setSaving(true);
      if (selected.id) {
        return axios
          .post(`${api_url}/accounts/${selected.id}`, {
            ...selected,
            _method: "put",
          })
          .then(() => {
            toggleForm();
            getAccounts(query);
            setSaving(false);
          })
          .catch((error) => {
            if (!isCancel(error)) setSaving(false);
          });
      }
      return axios
        .post(`${api_url}/accounts`, { ...selected })
        .then(() => {
          toggleForm();
          getAccounts(query);
          setSaving(false);
        })
        .catch((error) => {
          if (!isCancel(error)) setSaving(false);
        });
    },
    [query]
  );

  const deleteAccount = useAxiosCancel(
    (axios) => {
      setDeleting(true);
      if (selected.id)
        return axios
          .delete(`${api_url}/accounts/${selected.id}`)
          .then(() => {
            toggleConfirmation();
            getAccounts(query);
            setDeleting(false);
          })
          .catch((error) => {
            if (!isCancel(error)) setDeleting(false);
          });
    },
    [query]
  );

  const toggleForm = () => {
    setModalOpen(!modalOpen);
  };
  const toggleConfirmation = () => {
    setDeleteConfirmationOpen(!deleteConfirmationOpen);
  };

  const handleNewClick = () => {
    setSelected(empty);
    toggleForm();
  };
  const handleEditClick = (item) => {
    setSelected(item);
    toggleForm();
  };

  const handleDeleteClick = (item) => {
    setSelected(item);
    toggleConfirmation();
  };

  const handleLogout = () => {
    sessionStorage.clear();
    history.push("/");
  };
  return (
    <Grid container justify="center">
      <Modal
        open={modalOpen}
        title={selected.id ? selected.title : "Nueva Contraseña"}
        content={<PasswordForm item={selected} onChange={setSelected} />}
        actions={
          <FormActions
            onClose={toggleForm}
            onSave={saveAccount}
            saving={saving}
          />
        }
        onClose={toggleForm}
      />
      <Modal
        open={deleteConfirmationOpen}
        title={"Confirmar Borrado"}
        content={
          <p>
            Está seguro que borrar la cuenta <strong>{selected?.title}</strong>.
            <br />
            <p>Esta acción es irreversible.</p>
            <br />
          </p>
        }
        actions={
          <ConfirmActions
            onClose={toggleConfirmation}
            onConfirm={deleteAccount}
            confirming={deleting}
          />
        }
        onClose={toggleConfirmation}
      />
      <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
        <SearchBox onSubmit={setQuery} />

        <Grid
          container
          spacing={2}
          justify="space-between"
          style={{
            maxHeight: "calc(100vh - 89px)",
            paddingBottom: "89px",
            overflowY: "auto",
          }}
        >
          {loading ? (
            <Grid item xs={12}>
              <CircularProgress color="primary" />
            </Grid>
          ) : (
            accounts.map((item) => {
              return (
                <Grid item xs={12} sm={6} key={item.id}>
                  <PasswordCard
                    item={item}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{
            position: "absolute",
            width: "100%",
            bottom: "1.4rem",
            padding: "0 1rem",
            maxWidth: "600px",
            left: 0,
            right: 0,
            margin: "0 auto",
          }}
        >
          <Grid>
            <Fab color="primary" aria-label="logout" onClick={handleLogout}>
              <ExitToApp style={{ transform: "rotate(180deg)" }} />
            </Fab>
          </Grid>
          <Grid item>
            <Fab color="primary" aria-label="add" onClick={handleNewClick}>
              <Add />
            </Fab>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
