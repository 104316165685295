import { Delete, Edit, FileCopy, OpenInNew } from "@material-ui/icons";
import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useCallback } from "react";

import { Card } from "../card/card";
import { PasswordInput } from "../../../components/password_field/password_field";
import { useClipboard } from "../../../../app/hooks/use_clipboard";
import useStyles from "./styles";

export const PasswordCard = ({ item, onEdit, onDelete }) => {
  const [clipboard, setClipboard] = useClipboard();
  const handleEdit = useCallback(() => {
    onEdit(item);
  }, [item]);
  const handleDelete = useCallback(() => {
    onDelete(item);
  }, [item]);
  const classes = useStyles();
  return (
    <Card
      title={item.title}
      subtitle={
        <>
          {item.site.replaceAll("https://", "").replaceAll("http://", "")}{" "}
          <IconButton
            size="small"
            onClick={() =>
              window
                .open(
                  item.site.includes("http")
                    ? item.site
                    : `http://${item.site}`,
                  "_blank"
                )
                .focus()
            }
          >
            <OpenInNew fontSize="small" color="primary"></OpenInNew>
          </IconButton>
        </>
      }
      actions={
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <IconButton size="small" onClick={handleEdit}>
              <Edit fontSize="small" color="secondary"></Edit>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={handleDelete}>
              <Delete fontSize="small" color="error"></Delete>
            </IconButton>
          </Grid>
        </Grid>
      }
    >
      <Typography variant="subtitle2">Usuario:</Typography>
      <Typography>
        {item.user}{" "}
        <IconButton size="small" onClick={() => setClipboard(item.user)}>
          <FileCopy fontSize="small"></FileCopy>
        </IconButton>
      </Typography>
      <Typography variant="subtitle2">Contraseña:</Typography>
      <PasswordInput
        className={classes.password}
        value={item.password}
        variant="text"
        align="right"
        enableCopy={true}
        disabled
      />
    </Card>
  );
};
