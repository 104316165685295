import { useAxiosCancel } from "./use_axios_cancel";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const useAxiosAuth = (callback, deps, pushUrl, config) => {
  const history = useHistory();
  const request = useAxiosCancel(callback, deps, config);

  const newRequest = useCallback(() => {
    return request().catch((error) => {
      if (error?.response?.status === 401) {
        history.push(pushUrl);
        return;
      }
      throw error;
    });
  }, [...deps]);
  return newRequest;
};
