import { IconButton, InputBase, Paper } from "@material-ui/core";
import React, { useState } from "react";

import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./styles";

export function SearchBox({ onSubmit }) {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(query);
  };
  return (
    <Paper component="form" className={classes.search} onSubmit={handleSubmit}>
      <InputBase
        className={classes.input}
        placeholder="Buscar..."
        inputProps={{
          "aria-label": "search",
        }}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
