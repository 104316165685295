import React from "react";
import { Redirect } from "react-router-dom";
import { useSessionStorage } from "../../../../app/hooks/use_storage";

export const CheckAuth = () => {
  const [token] = useSessionStorage(null, "token");
  if (!token) {
    sessionStorage.clear();
    return <Redirect to="/" />;
  }
  return null;
};
