import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";

import Modal from "../../components/modal/modal";
import { useAxiosCancel } from "../../../app/hooks/use_axios_cancel";

const api_url = process.env.REACT_APP_API_URL;

export const ForgotPassword = ({ user }) => {
  const [show, setShow] = useState(false);
  const requestReset = useAxiosCancel((axios) => {
    return axios.post(`${api_url}/request-password-reset`, {
      slug: user,
    });
  });
  const toggleShow = () => {
    setShow(!show);
  };
  const handleClick = () => {
    toggleShow();
    requestReset();
  };

  return (
    <>
      <Modal
        open={show}
        title={"Reseteo de Contraseña"}
        content={
          <>
            <p>Se ha enviado un correo al email asociado a tu cuenta. </p>
            <p>
              Para restaurar tu contraseña deberás hacer click en el enlace que
              se encuentra en dicho correo.
            </p>
          </>
        }
        actions={
          <Button variant="contained" color="primary" onClick={toggleShow}>
            ACEPTAR
          </Button>
        }
        onClose={toggleShow}
      />
      <Typography
        component="a"
        variant="caption"
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      >
        ¿Olvidate tu contraseña?
      </Typography>
    </>
  );
};
