import { Avatar, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useAxiosCancel } from "../../../../app/hooks/use_axios_cancel.jsx";
import useStyles from "./styles.jsx";

const api_url = process.env.REACT_APP_API_URL;

const ProfileSelectionPage = () => {
  const [users, setUsers] = useState([]);
  const getUsers = useAxiosCancel((axios) => {
    return axios
      .get(`${api_url}/users`)
      .then((response) => setUsers(response.data))
      .catch();
  });
  useEffect(() => {
    getUsers();
  }, []);
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        justify="space-evenly"
        alignContent="center"
        direction="row"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.header}
          >
            Seleccioná un perfil
          </Typography>
        </Grid>
        {users.map((user) => {
          return (
            <Grid key={user.id} item className={classes.selector}>
              <Link to={`login/${user.username}`} className={classes.link}>
                <Avatar
                  src={
                    (user.image && `${api_url}/${user.image}`) ||
                    "https://picsum.photos/200/200"
                  }
                  className={classes.profileImg}
                />
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.profile}
                >
                  {user.name}
                </Typography>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ProfileSelectionPage;
