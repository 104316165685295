import "./App.css";

import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { CheckAuth } from "./features/dashboard/components/check_auth/check_auth";
import { Dashboard } from "./features/dashboard/pages/dashboard";
import { LoginPage } from "./features/login/pages/login_page/login_page";
import { PasswordResetPage } from "./features/login/pages/password-reset.jsx/password_reset_page";
import ProfileSelectionPage from "./features/login/pages/profile_selection_page/profile_selection_page";
import { Provider } from "react-redux";
import React from "react";
import lightTheme from "./app/themes/dark_theme";
import { store } from "./app/store";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route path="/" exact>
                <ProfileSelectionPage />
              </Route>
              <Route path="/login/:slug" exact>
                <LoginPage />
              </Route>
              <Route path="/password/reset/:token" exact>
                <PasswordResetPage />
              </Route>
              <Route path="/:slug">
                <>
                  <CheckAuth />
                  <Dashboard />
                </>
              </Route>
              <NotFound />
            </Switch>
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;

export const NotFound = () => {
  return <div>404 | Page Not Found</div>;
};
