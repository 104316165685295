import { Button, Grid } from "@material-ui/core";

import React from "react";

export function FormActions({ onClose, onSave, saving }) {
  return (
    <Grid container justify="flex-end" spacing={2}>
      <Grid item>
        <Button variant="contained" color="secondary" onClick={onClose}>
          VOLVER
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={saving}
        >
          {saving ? "GUARDANDO" : "GUARDAR"}
        </Button>
      </Grid>
    </Grid>
  );
}
