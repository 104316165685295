import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  password: {
    ["& .MuiInputBase-input"]: {
      color: theme.palette.common.white,
    },
  },
}));

export default useStyles;
