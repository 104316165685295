import { Button, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Modal from "../../../components/modal/modal";
import { PasswordInput } from "../../../components/password_field/password_field";
import { useAxiosCancel } from "../../../../app/hooks/use_axios_cancel";

const api_url = process.env.REACT_APP_API_URL;

export const PasswordResetPage = () => {
  const { token } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow(!show);
  };
  const handleLogin = () => {
    history.push("/");
  };
  const reset = useAxiosCancel((axios) => {
    setLoading(true);
    return axios
      .post(`${api_url}/reset-password`, { password, token })
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              setError("Contraseña inválida");
              break;
            default:
              setError("Ha ocurrido un error");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    reset();
  };
  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <>
      <Modal
        open={show}
        title={"¡Restauración Exitosa!"}
        content={
          <>
            <p>Se ha restaurado correctamente tu contraseña</p>
            <p>
              Para volver acceder con tu nueva contraseña, haz click en{" "}
              <strong>Acceder</strong>
            </p>
          </>
        }
        actions={
          <Button variant="contained" color="primary" onClick={handleLogin}>
            ACCEDER
          </Button>
        }
        onClose={toggleShow}
      />
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Paper style={{ padding: "1rem", maxWidth: "320px", width: "100%" }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography variant="h5">Restaurar Contraseña</Typography>
              </Grid>
              <Grid item>
                <PasswordInput
                  variant="outlined"
                  label="Nueva Contraseña"
                  type="password"
                  value={password}
                  name="password"
                  onChange={handleChange}
                  fullWidth
                  error={error}
                  helperText={error}
                />
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                  >
                    {loading ? "Enviando..." : "Enviar"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </>
  );
};
