import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  outlinedLabel: {
    ["&.MuiInputLabel-shrink"]: {
      backgroundColor: theme.palette.background.paper,
      padding: "0 5px",
    },
  },
  root: {
    ["& .MuiOutlinedInput-notchedOutline"]: {
      border: (props) => props.variant === "text" && "none",
    },
    ["& .MuiInputBase-input"]: {
      padding: (props) => props.variant === "text" && "6.5px 0px 6.5px 14px",
      textAlign: (props) => props.align,
    },
  },
}));
export default useStyles;
