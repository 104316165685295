import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import React, { useState } from "react";

import { ForgotPassword } from "../../components/forgot_password";
import { PasswordInput } from "../../../components/password_field/password_field";
import { useAxiosCancel } from "../../../../app/hooks/use_axios_cancel";
import { useSessionStorage } from "../../../../app/hooks/use_storage";

const api_url = process.env.REACT_APP_API_URL;

export const LoginPage = () => {
  const { slug } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [token, setToken] = useSessionStorage(null, "token");
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const login = useAxiosCancel((axios) => {
    setLoading(true);
    return axios
      .post(`${api_url}/login`, { username: slug, password })
      .then((response) => {
        setToken(response.data.token);
        history.push(`/${slug}`);
      })
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              setError("Contraseña incorrecta");
              break;
            default:
              setError("Ha ocurrido un error");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };
  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Paper style={{ padding: "1rem", maxWidth: "320px", width: "100%" }}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h5">Login</Typography>
            </Grid>
            <Grid item>
              <PasswordInput
                variant="outlined"
                label="Contraseña"
                type="password"
                value={password}
                name="password"
                onChange={handleChange}
                fullWidth
                error={error}
                helperText={error}
              />
            </Grid>
            <Grid
              item
              style={{
                alignSelf: "flex-end",
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <ForgotPassword user={slug} />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={6}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="secondary" fullWidth>
                    Volver
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                >
                  {loading ? "Enviando..." : "Acceder"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};
