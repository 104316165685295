import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  profileImg: {
    margin: "0 auto 1rem auto",
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  },
  profile: {
    fontWeight: 700,
    fontSize: "1rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
  },
  header: {
    paddingBottom: "3rem",
    fontSize: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.5rem",
    },
  },
  selector: {
    margin: ".5rem",
  },
  link: {
    textDecoration: "none",
    ["& .MuiAvatar-root"]: {
      boxShadow: theme.shadows[3],
    },
    ["&:hover .MuiTypography-root"]: {
      color: theme.palette.text.primary,
    },
    ["&:hover .MuiAvatar-root"]: {
      border: `5px solid ${theme.palette.common.white}`,
      boxShadow: "0px 0px 15px 7px #F33B54",
    },
  },
}));

export default useStyles;
