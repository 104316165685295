import { useEffect, useState } from "react";

function getStoredValue(storage, init, key) {
  const item = storage.getItem(key);
  if (item) return JSON.parse(item);

  if (init instanceof Function) return init();
  return init;
}

export const useSessionStorage = (initialValue, key) => {
  const [value, setValue] = useState(() => {
    return getStoredValue(sessionStorage, initialValue, key);
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};

export const useLocalStorage = (initialValue, key) => {
  const [value, setValue] = useState(() => {
    return getStoredValue(localStorage, initialValue, key);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};
