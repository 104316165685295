import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useState } from "react";
import { useClipboard } from "./../../../app/hooks/use_clipboard";
import useStyles from "./styles";

export const PasswordInput = ({
  value,
  onChange,
  className,
  fullWidth,
  error,
  helperText,
  disabled,
  name,
  variant,
  label,
  autoFocus,
  align,
  enableCopy,
}) => {
  const [show, setShow] = useState(false);
  const [clipboard, setClipboard] = useClipboard();
  const classes = useStyles({ variant, align });
  const handleShow = () => {
    setShow(!show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      error={error}
      variant={variant}
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <InputLabel
        htmlFor="adornment-password"
        className={
          variant === "outlined" || variant === "text"
            ? classes.outlinedLabel
            : ""
        }
      >
        {label}
      </InputLabel>
      {variant === "outlined" || variant === "text" ? (
        <OutlinedInput
          name={name}
          id="adornment-password"
          type={show ? "text" : "password"}
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
          className={classes.root}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShow}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
              >
                {show ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
              {enableCopy && (
                <IconButton
                  size="small"
                  onClick={() => setClipboard(value)}
                  style={{ marginLeft: ".5rem" }}
                >
                  <FileCopy fontSize="small"></FileCopy>
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      ) : (
        <FilledInput
          name={name}
          id="filled-adornment-password"
          type={show ? "text" : "password"}
          value={value}
          onChange={onChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleShow}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {show ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      )}
      <FormHelperText id="component-helper-text">{helperText}</FormHelperText>
    </FormControl>
  );
};
