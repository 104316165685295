import {
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

export const Card = ({ title, subtitle, children, actions }) => {
  const classes = useStyles();
  return (
    <MuiCard className={classes.root}>
      <CardHeader title={title} className={classes.header}></CardHeader>
      <CardContent>
        <Typography className={classes.pos} color="textSecondary">
          {subtitle}
        </Typography>
        <Typography variant="body2" component="p">
          {children}
        </Typography>
      </CardContent>
      <CardActions>{actions}</CardActions>
    </MuiCard>
  );
};
