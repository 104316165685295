import { Grid, TextField } from "@material-ui/core";

import { PasswordInput } from "../../../components/password_field/password_field";
import React from "react";

export const PasswordForm = ({ item, onChange }) => {
  const handleChange = (e) => {
    onChange({ ...item, [e.target.name]: e.target.value });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          type="text"
          label="Título*"
          name="title"
          fullWidth
          value={item.title || ""}
          onChange={handleChange}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          type="text"
          label="URL Sitio"
          fullWidth
          name="site"
          value={item.site || ""}
          onChange={handleChange}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          type="text"
          label="Usuario*"
          fullWidth
          name="user"
          value={item.user}
          onChange={handleChange}
        ></TextField>
      </Grid>
      <Grid item xs={6}>
        <PasswordInput
          label="Contraseña*"
          variant="outlined"
          name="password"
          value={item.password || ""}
          onChange={handleChange}
          fullWidth
        ></PasswordInput>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          type="text"
          label="Palabras Claves"
          fullWidth
          name="tags"
          value={item.tags || ""}
          onChange={handleChange}
        ></TextField>
      </Grid>
    </Grid>
  );
};
